<template>

  <div class="prd_edit">

    <div class="modal_user_content" v-if="edit_data">

        <div class="settings_description">

            <div expanded>
              <NuxtLink to="/brands-manager/products" exact class="right_link">&lt; {{ $t('brand_manager_view_tab_products_back_link') }}</NuxtLink>
            </div>
            <h2>{{ $t('brand_manager_view_tab_products_edit_product') }} - {{ edit_data.name ? edit_data.name : 'New' }}</h2>

          </div>

          <div style="clear:both">&nbsp;</div>
    
          <form id="form_product">

            <div class="nr">

                <div class="av">
                  <img class="avatar_user avatar_item" :src="edit_data.cover_s ? edit_data.cover_s : '/images/product-empty-avatar.png'" data-not-lazy>
                </div>

                <div class="nm">
                  <div class="field">

                    <div class="label">{{ $t('brand_manager_view_tab_products_name') }}</div>
                    <n-input
                      v-model:value="edit_data.name" 
                      type="text" 
                      :value="edit_data.name" 
                      @input="validateName" 
                      :placeholder="$t('brand_manager_view_tab_products_name')" 
                      :status="validationErrors.name ? 'error' : ''"
                    />

                    <n-alert v-if="validationErrors.name"
                      title="" 
                      :show-icon="false"
                      :type="validationErrors.name ? 'error' : 'default'"
                     >
                      {{validationErrors.name ? validationErrors.name.message : ''}}
                    </n-alert>

                  </div>

                  <div style="clear:both">&nbsp;</div>

                  <div class="field fup">
                    <n-upload
                      ref="uploadLogoList"
                      :custom-request="submitAvatar"
                      :show-file-list="false"
                      >
                      <UiButton
                        :name="$t('brand_manager_view_tab_products_cover')"
                        />
                    </n-upload>
                  </div>

                </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <n-alert v-if="validationErrors.logo"
              title="" 
              :show-icon="false"
              :type="validationErrors.logo ? 'error' : 'default'"
             >
              {{validationErrors.logo ? validationErrors.logo.message : ''}}
            </n-alert>


            <h2>{{ $t('brand_manager_view_tab_banners_features') }}</h2>

            <div class="two_fld">

               <div class="group50">
                  
                  <div class="label">{{ $t('brand_manager_view_tab_products_type_gender') }}</div>
                  <client-only>
                  <n-select
                    v-model:value="edit_data.type_gender"
                    filterable
                    :placeholder="$t('brand_manager_view_tab_products_type_gender')"
                    :options="multipleTypeGenderAvaliable"
                    value-field="id"
                    label-field="name"
                  />
                </client-only>

                  <n-alert v-if="validationErrors.type_gender"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.type_gender ? 'error' : 'default'"
                   >
                    {{validationErrors.type_gender ? validationErrors.type_gender.message : ''}}
                  </n-alert>
                  
               </div>
                  
               <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_genetics') }}</div>
                  <n-input 
                    v-model:value="edit_data.gen" 
                    type="text" 
                    :value="edit_data.gen" 
                    @input="validateSeedGenetics" 
                    :placeholder="$t('brand_manager_view_tab_products_genetics')" 
                    :status="validationErrors.gen ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.gen"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.gen ? 'error' : 'default'"
                   >
                    {{validationErrors.gen ? validationErrors.gen.message : ''}}
                  </n-alert>

               </div>

            </div>

            <div class="two_fld">

               <div class="group50">
                  
                  <div class="label">{{ $t('brand_manager_view_tab_products_type_flowering') }}</div>
                  <client-only>
                  <n-select
                    v-model:value="edit_data.type_flowering"
                    filterable
                    :placeholder="$t('brand_manager_view_tab_products_type_flowering')"
                    :options="multipleTypeFloweringAvaliable"
                    value-field="id"
                    label-field="name"
                    @update:value="clearData()"
                  />
                </client-only>

                  <n-alert v-if="validationErrors.type_flowering"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.type_flowering ? 'error' : 'default'"
                   >
                    {{validationErrors.type_flowering ? validationErrors.type_flowering.message : ''}}
                  </n-alert>
                  
               </div>
                  
               <div class="group50" v-if="false">

                  <div class="label">{{ $t('brand_manager_view_tab_products_type_source') }}</div>
                  <client-only>
                  <n-select
                    v-model:value="edit_data.type_source"
                    filterable
                    :placeholder="$t('brand_manager_view_tab_products_type_source')"
                    :options="multipleTypeSourceAvaliable"
                    value-field="id"
                    label-field="name"
                  />
                </client-only>

                  <n-alert v-if="validationErrors.type_source"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.type_source ? 'error' : 'default'"
                   >
                    {{validationErrors.type_source ? validationErrors.type_source.message : ''}}
                  </n-alert>

               </div>

            </div>

            <div class="two_fld">

              <div class="group50">

                  <div class="subgroup50">
                    <div class="label">{{ $t('brand_manager_view_tab_products_height_min') }} (cm)</div>
                    <n-input 
                      v-model:value="edit_data.height_min" 
                      type="text" 
                      :value="-1" 
                      @input="validateSeedHeight" 
                      :placeholder="$t('brand_manager_view_tab_products_height_min')" 
                      :status="validationErrors.height_min ? 'error' : ''"
                    />

                    <n-alert v-if="validationErrors.height_min"
                      title="" 
                      :show-icon="false"
                      :type="validationErrors.height_min ? 'error' : 'default'"
                     >
                      {{validationErrors.height_min ? validationErrors.height_min.message : ''}}
                    </n-alert>
                </div>
                
                <div class="subgroup50">
                    <div class="label">{{ $t('brand_manager_view_tab_products_height_max') }} (cm)</div>
                    <n-input 
                      v-model:value="edit_data.height_max" 
                      type="text"
                      @input="validateSeedHeight" 
                      :placeholder="$t('brand_manager_view_tab_products_height_max')" 
                      :status="validationErrors.height_max ? 'error' : ''"
                    />

                    <n-alert v-if="validationErrors.height_max"
                      title="" 
                      :show-icon="false"
                      :type="validationErrors.height_max ? 'error' : 'default'"
                     >
                      {{validationErrors.height_max ? validationErrors.height_max.message : ''}}
                    </n-alert>
                </div>

              </div>

              <div class="group50">
              
                <div class="subgroup50">
                    <div class="label">{{ $t('brand_manager_view_tab_products_harvest_min') }} (gr/sqm)</div>
                    <n-input 
                      v-model:value="edit_data.harvest_min" 
                      type="text" 
                      :value="-1" 
                      @input="validateSeedHarvest" 
                      :placeholder="$t('brand_manager_view_tab_products_harvest_min')" 
                      :status="validationErrors.harvest_min ? 'error' : ''"
                    />

                    <n-alert v-if="validationErrors.harvest_min"
                      title="" 
                      :show-icon="false"
                      :type="validationErrors.harvest_min ? 'error' : 'default'"
                     >
                      {{validationErrors.harvest_min ? validationErrors.harvest_min.message : ''}}
                    </n-alert>
                </div>
                
                <div class="subgroup50">
                    <div class="label">{{ $t('brand_manager_view_tab_products_harvest_max') }} (gr/sqm)</div>
                    <n-input 
                      v-model:value="edit_data.harvest_max" 
                      type="text"
                      @input="validateSeedHarvest" 
                      :placeholder="$t('brand_manager_view_tab_products_harvest_max')" 
                      :status="validationErrors.harvest_max ? 'error' : ''"
                    />

                    <n-alert v-if="validationErrors.harvest_max"
                      title="" 
                      :show-icon="false"
                      :type="validationErrors.harvest_max ? 'error' : 'default'"
                     >
                      {{validationErrors.harvest_max ? validationErrors.harvest_max.message : ''}}
                    </n-alert>
                </div>
                
              </div>

            </div>

            <div class="two_fld">

              <div class="group50">

                <div class="subgroup50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_flowering_min') }} (days)</div>
                  <n-input 
                    v-model:value="edit_data.flowering_min" 
                    type="text"
                    @input="validateSeedFlowering" 
                    :placeholder="$t('brand_manager_view_tab_products_flowering_min')" 
                    :status="validationErrors.flowering_min ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.flowering_min"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.flowering_min ? 'error' : 'default'"
                   >
                    {{validationErrors.flowering_min ? validationErrors.flowering_min.message : ''}}
                  </n-alert>
                  
                </div>
                
                <div class="subgroup50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_flowering_max') }} (days)</div>
                  <n-input 
                    v-model:value="edit_data.flowering_max" 
                    type="text"
                    @input="validateSeedFlowering" 
                    :placeholder="$t('brand_manager_view_tab_products_flowering_max')" 
                    :status="validationErrors.flowering_max ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.flowering_max"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.flowering_max ? 'error' : 'default'"
                   >
                    {{validationErrors.flowering_max ? validationErrors.flowering_max.message : ''}}
                  </n-alert>
                  
                </div>

              </div>
              
              <div class="group50">

                <div class="subgroup50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_thc_min') }} (%)</div>
                  <n-input 
                    v-model:value="edit_data.thc_min" 
                    type="text"
                    @input="validateSeedThc" 
                    :placeholder="$t('brand_manager_view_tab_products_thc_min')" 
                    :status="validationErrors.thc_min ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.thc_min"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.thc_min ? 'error' : 'default'"
                   >
                    {{validationErrors.thc_min ? validationErrors.thc_min.message : ''}}
                  </n-alert>
                  
                </div>

                <div class="subgroup50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_thc_max') }} (%)</div>
                  <n-input 
                    v-model:value="edit_data.thc_max" 
                    type="text"
                    @input="validateSeedThc" 
                    :placeholder="$t('brand_manager_view_tab_products_thc_max')" 
                    :status="validationErrors.thc_max ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.thc_max"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.thc_max ? 'error' : 'default'"
                   >
                    {{validationErrors.thc_max ? validationErrors.thc_max.message : ''}}
                  </n-alert>
                  
                </div>

              </div>

            </div>

            <div class="two_fld">

              <div class="group50">

                <div class="subgroup50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_cbd_min') }} (%)</div>
                  <n-input 
                    v-model:value="edit_data.cbd_min" 
                    type="text"
                    @input="validateSeedCbd" 
                    :placeholder="$t('brand_manager_view_tab_products_cbd_min')" 
                    :status="validationErrors.cbd_min ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.cbd_min"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.cbd_min ? 'error' : 'default'"
                   >
                    {{validationErrors.cbd_min ? validationErrors.cbd_min.message : ''}}
                  </n-alert>
                  
                </div>
                
                <div class="subgroup50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_cbd_max') }} (%)</div>
                  <n-input 
                    v-model:value="edit_data.cbd_max" 
                    type="text"
                    @input="validateSeedCbd" 
                    :placeholder="$t('brand_manager_view_tab_products_cbd_max')" 
                    :status="validationErrors.cbd_max ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.cbd_max"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.cbd_max ? 'error' : 'default'"
                   >
                    {{validationErrors.cbd_max ? validationErrors.cbd_max.message : ''}}
                  </n-alert>
                  
                </div>

              </div>
              
              <div class="group50">

              </div>

            </div>

            <div class="two_fld">

              <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_tastes') }}</div>
                  <client-only>
                  <n-select
                    v-model:value="multipleTasteSelections"
                    :status="validationErrors.tastes ? 'is-danger' : ''"
                    filterable
                    multiple
                    :placeholder="$t('brand_manager_view_tab_products_tastes')"
                    :options="edit_data.items_taste_avaliable"
                    value-field="id"
                    label-field="name"
                    @update:value="validateSeedTastes()"
                  />
                </client-only>

                  <n-alert v-if="validationErrors.tastes"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.tastes ? 'error' : 'default'"
                   >
                    {{validationErrors.tastes ? validationErrors.tastes.message : ''}}
                  </n-alert>

              </div>

              <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_effects') }}</div>
                  <client-only>
                  <n-select
                    v-model:value="multipleEffectSelections"
                    :status="validationErrors.effects ? 'is-danger' : ''"
                    filterable
                    multiple
                    :placeholder="$t('brand_manager_view_tab_products_effects')"
                    :options="edit_data.items_effect_avaliable"
                    value-field="id"
                    label-field="name"
                    @update:value="validateSeedEffects()"
                  />
                </client-only>

                  <n-alert v-if="validationErrors.effects"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.effects ? 'error' : 'default'"
                   >
                    {{validationErrors.effects ? validationErrors.effects.message : ''}}
                  </n-alert>

              </div>

            </div>

            <div class="two_fld">

               <div class="group50">
                  
                  <div class="label">{{ $t('brand_manager_view_tab_products_release_year') }}</div>
                  <client-only>
                  <n-select
                    v-model:value="release_year"
                    filterable
                    :placeholder="$t('brand_manager_view_tab_products_release_year')"
                    :options="multipleReleaseYearAvaliable"
                    value-field="id"
                    label-field="name"
                    @update:value="validateReleaseYear"
                  />
                </client-only>

                  <n-alert v-if="validationErrors.release_year"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.release_year ? 'error' : 'default'"
                   >
                    {{validationErrors.release_year ? validationErrors.release_year.message : ''}}
                  </n-alert>
                  
               </div>
                  
               <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_link') }}</div>
                  <n-input 
                    v-model:value="edit_data.url_website" 
                    type="text" 
                    :value="edit_data.url_website" 
                    @input="validateWebLink" 
                    :placeholder="$t('brand_manager_view_tab_products_link')" 
                    :status="validationErrors.url_website ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.url_website"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.url_website ? 'error' : 'default'"
                   >
                    {{validationErrors.url_website ? validationErrors.url_website.message : ''}}
                  </n-alert>

               </div>

            </div>

            <div class="two_fld">

               <div class="group50">

                  {{ $t('brand_manager_view_tab_products_genes') }} &nbsp;&nbsp;
                  <n-checkbox v-model:checked="genes"></n-checkbox>

               </div>
                  
               <div class="group50">
                  
               </div>

            </div>

            <div class="two_fld">

               <div class="group50">

                  <n-slider :step="1" v-model:value="edit_data.indica_sativa" :tooltip="false" :disabled="!genes" :min=0 :max=100 />
                  <div class="indica">{{  $t('harvest_view_effect_indica') }}, {{  edit_data.indica_sativa }}%</div> 
                  <div class="sativa">{{  $t('harvest_view_effect_sativa') }}, {{  100-edit_data.indica_sativa }}%</div>

               </div>

               <div class="group50">

               </div>

            </div>

            <div class="two_fld">

               <div class="group50">

                  <n-switch v-model:value="edit_data.is_test" :checked-value="1" :unchecked-value="0" @update:value="clearDataTest()" />
                  &nbsp;&nbsp;{{ $t('brand_manager_view_tab_products_test') }}

               </div>

               <div class="group50">

               </div>

            </div>

            <div class="two_fld" v-if="false">

               <div class="group50">

                  <n-switch v-model:value="edit_data.is_bestseller" :checked-value="1" :unchecked-value="0" />
                  &nbsp;&nbsp;{{ $t('brand_manager_view_tab_products_bestseller') }}

               </div>

               <div class="group50">

               </div>

            </div>

            <div class="two_fld" v-if="edit_data.type_flowering == 'auto'">

               <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_ruderalis') }}</div>
                  <n-slider :step="1" v-model:value="edit_data.ruderalis" :tooltip="false"/>
                  <div class="indica">{{  edit_data.ruderalis }}%</div> 
                  <div class="sativa">{{  100-edit_data.ruderalis }}%</div>

               </div>

               <div class="group50">
                
                <div class="subgroup50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_vegetation_min') }} (days)</div>
                  <n-input 
                    v-model:value="edit_data.vegetation_min" 
                    type="text"
                    @input="validateSeedVegetation" 
                    :placeholder="$t('brand_manager_view_tab_products_vegetation_min')" 
                    :status="validationErrors.vegetation_min ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.vegetation_min"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.vegetation_min ? 'error' : 'default'"
                   >
                    {{validationErrors.vegetation_min ? validationErrors.vegetation_min.message : ''}}
                  </n-alert>
                  
                </div>
                
                <div class="subgroup50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_vegetation_max') }} (days)</div>
                  <n-input 
                    v-model:value="edit_data.vegetation_max" 
                    type="text"
                    @input="validateSeedVegetation" 
                    :placeholder="$t('brand_manager_view_tab_products_vegetation_max')" 
                    :status="validationErrors.vegetation_max ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.vegetation_max"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.vegetation_max ? 'error' : 'default'"
                   >
                    {{validationErrors.vegetation_max ? validationErrors.vegetation_max.message : ''}}
                  </n-alert>
                  
                </div>

              </div>

            </div>

            <div class="two_fld">

              <div class="label">{{ $t('brand_manager_view_tab_products_desc') }}</div>
              <n-input 
                v-model:value="edit_data.description" 
                type="textarea" 
                :value="edit_data.description" 
                @input="validateDesc"
                :placeholder="$t('brand_manager_view_tab_products_desc')" 
                :status="validationErrors.description ? 'error' : ''"
                :has-counter="hasCounter"
                :rows="8"
              />

              <div class="field-words"><div class="words">{{ $t('brand_manager_view_description_word_count') }}: <span>{{ wordsCount }}</span></div></div>

              <n-alert v-if="validationErrors.description"
                title="" 
                :show-icon="false"
                :type="validationErrors.description ? 'error' : 'default'"
               >
                {{validationErrors.description ? validationErrors.description.message : ''}}
              </n-alert>

            </div>

            <div class="two_fld">

              <div class="label">{{ $t('brand_manager_view_tab_products_photos') }}</div>
              <n-upload
                ref="uploadPhotoList"
                :custom-request="submitPhoto"
                :show-file-list="false"
                :disabled = "isUploading"
                multiple="true"
                >
                <n-upload-dragger class="no-padding">
                  <div class="load_box_photos">
                    <label>{{ $t('brand_manager_view_tab_brand_click_to_upload') }}</label>
                  </div>
                </n-upload-dragger>
              </n-upload>

             <div style="clear:both">&nbsp;</div>

             <div class="upload_photos" expanded>
                <draggable v-model="edit_data.items_photo" item-key="tmp_id" v-if="edit_data.items_photo" @start="drag=true"  @end="drag=false" >

                    <template #item="{element}">
                      <div class="item">
                        <div class="item_table">
                          <div class="item_td">
                            <div class="delete" @click="removePhoto(element)">
                              <b-icon pack="fas" icon="circle" size="is-small"></b-icon>
                            </div>
                            <img :src="element.url_s || element.temp">
                          </div>
                        </div>
                      </div>
                    </template>

                </draggable>
             </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <n-alert v-if="validationErrors.photos"
              title="" 
              :show-icon="false"
              :type="validationErrors.photos ? 'error' : 'default'"
             >
              {{validationErrors.photos ? validationErrors.photos.message : ''}}
            </n-alert>

            <div style="clear:both">&nbsp;</div>

            <div style="display: flex; gap:0.5rem">
              <UiButton
                :name="$t('universal_button_save')"
                :is-loading="isLoading"
                :type="'primary'"
                @click="saveProduct()"
                />
              <span v-if="edit_data.change_cnt" class="changeset">&nbsp; {{ edit_data.change_cnt + ' ' + $t('brand_manager_view_moderation_mark')}}</span>
            </div>

          </form>

        </div>

    </div>

</template>

<script setup>

import { NButton, NSelect, NInput, NAlert, NUpload, NUploadDragger, useMessage, NMessageProvider, NCheckbox, NSlider, NSwitch } from 'naive-ui'
import draggable from 'vuedraggable'
import editFuncs from '/pages/brands-manager/func/editProduct.js'
import Utils from '/pages/brands-manager/func/utils.js'
import { useI18n } from "vue-i18n"

const {$api} = useNuxtApp()
const { t } = useI18n()
const message = useMessage()
const route = useRoute();

const props = defineProps({
  edit_data: {
    type: Object
  }
});

const avatar = ref('')
const hasCounter = ref(false)
const wordsCount = ref(0)
const validationErrors = ref({})
const isLoading = ref(false)
const drag = ref(false)
const edit_data = ref(props.edit_data)
const multipleTypeGenderAvaliable = ref([])
const multipleTypeFloweringAvaliable = ref([])
const multipleTypeSourceAvaliable = ref([])
const multipleTasteSelections = ref([])
const multipleEffectSelections = ref([])
const multipleReleaseYearAvaliable = ref([])
const genes = ref(true)
const release_year = ref([{id:0,name:''}])
const uploadLogoList = ref(null)
const uploadPhotoList = ref(null)
const isUploading = ref(false)

const editDataClone = Utils.makeClone(edit_data.value);

// init type gender list 
for(var k in edit_data.value.items_type_gender_avaliable){
   multipleTypeGenderAvaliable.value.push({id:k, name:t(edit_data.value.items_type_gender_avaliable[k])});
}

// init type flowering list 
for(var k in edit_data.value.items_type_flowering_avaliable){
   multipleTypeFloweringAvaliable.value.push({id:k, name:t(edit_data.value.items_type_flowering_avaliable[k])});
}

// init type source list 
for(var k in edit_data.value.items_type_source_avaliable){
   multipleTypeSourceAvaliable.value.push({id:k, name:t(edit_data.value.items_type_source_avaliable[k])});
}

// init tastes
for(var k of edit_data.value.items_taste){
  for(var k2 of edit_data.value.items_taste_avaliable){
    if(k2.name.toLowerCase() == k) 
      multipleTasteSelections.value.push(k2.id);
  }
}

// init effects
for(var k of edit_data.value.items_effect){
  for(var k2 of edit_data.value.items_effect_avaliable){
    if(k2.name.toLowerCase() == k) 
      multipleEffectSelections.value.push(k2.id);
  }
}

// init other 
edit_data.value.harvest_min = edit_data.value.harvest_min ? edit_data.value.harvest_min : '';
edit_data.value.harvest_max = edit_data.value.harvest_max ? edit_data.value.harvest_max : '';
edit_data.value.flowering_min = edit_data.value.flowering_min ? edit_data.value.flowering_min : '';
edit_data.value.flowering_max = edit_data.value.flowering_max ? edit_data.value.flowering_max : '';
edit_data.value.thc_min = edit_data.value.thc_min ? edit_data.value.thc_min : '';
edit_data.value.thc_max = edit_data.value.thc_max ? edit_data.value.thc_max : '';
edit_data.value.cbd_min = edit_data.value.cbd_min ? edit_data.value.cbd_min : '';
edit_data.value.cbd_max = edit_data.value.cbd_max ? edit_data.value.cbd_max : '';
edit_data.value.height_min = edit_data.value.height_min ? edit_data.value.height_min : '';
edit_data.value.height_max = edit_data.value.height_max ? edit_data.value.height_max : '';
edit_data.value.vegetation_min = edit_data.value.vegetation_min ? edit_data.value.vegetation_min : '';
edit_data.value.vegetation_max = edit_data.value.vegetation_max ? edit_data.value.vegetation_max : '';
edit_data.value.indica_sativa = edit_data.value.indica_sativa == -1 ? 0 : edit_data.value.indica_sativa;
genes.value = edit_data.value.indica_sativa >=0 ? true : false;

// init type
//if(edit_data.value.type_of.length && !edit_data.value.type_of[0]) edit_data.value.type_of[0] = 'fem';
  //else edit_data.value.type_of = ['fem'];
//if(!edit_data.value.type_gender.length) edit_data.value.type_gender = 'fem';

// init release year 
for(var k of edit_data.value.items_release_year_avaliable){
  if(k.id == 0) multipleReleaseYearAvaliable.value.push({id:k.id, name:''});
  else if(k.id == 1) multipleReleaseYearAvaliable.value.push({id:k.id, name:t(k.name)});
  else if(k.id == 2) multipleReleaseYearAvaliable.value.push({id:k.id, name:t(k.name)});
  else multipleReleaseYearAvaliable.value.push({id:k.id, name:k.name});
}

for(var k of edit_data.value.items_release_year_avaliable){
  if(k.id == 0 && edit_data.value.release_year == 0 && edit_data.value.is_test){
    release_year.value = k.id;
    break;
  }else if(k.id == 1 && !edit_data.value.is_test && edit_data.value.release_year != 0 && edit_data.value.release_year != 2 && !edit_data.value.discontinued && (edit_data.value.release_year == '' || edit_data.value.release_year < ((new Date()).getFullYear()-3) || !edit_data.value.id)){
    release_year.value = k.id;
    break;
  }else if(k.id == 2 && (edit_data.value.release_year == 2 || edit_data.value.discontinued)){
    release_year.value = k.id;
    break;
  }else if(k.id == edit_data.value.release_year){
    release_year.value = k.id;
    break;
  }
}
//console.log(release_year.value);
//console.log(multipleReleaseYearAvaliable);
//if(!release_year.value.id){
  //for(var y=(new Date()).getFullYear()-3;y<=(new Date()).getFullYear();y++){
    //if(edit_data.value.release_year == y) release_year.value = y;
  //}
//}

if(!edit_data.value.items_photo) edit_data.value.items_photo = [];

const submitAvatar = (data) => {
  let avatar_obj = data.file.file;

  if(avatar_obj.type != 'image/jpeg' && avatar_obj.type != 'image/png' && avatar_obj.type != 'image/heicd'  && avatar_obj.type != 'image/webp'){
    message.error(t('brand_manager_view_tab_products_cover_format_error'));
    uploadLogoList.value.clear();
    return false;
  }

  let reader = new FileReader();
  reader.readAsDataURL(avatar_obj);
  reader.onload = evt => {
    let img = new Image();
    img.onload = () => {

      if(img.height  != '1400' || img.width != '1000') {
        message.error(t('brand_manager_view_tab_products_cover_size_error'));
        uploadLogoList.value.clear();
        return false;
      }
      
      uploadAvatar(avatar_obj);
    }
    img.src = evt.target.result;
  }

  reader.onerror = evt => {
    console.error(evt);
  }
}

const uploadAvatar = async (avatar_obj) => {
  let formData = new FormData(); 
  formData.append('file', avatar_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      avatar.value = res.path;
      edit_data.value.cover_s = res.full_path;
      uploadLogoList.value.clear();
    }else {
      uploadLogoList.value.clear();
    }
  }).catch(function (error) {
     uploadLogoList.value.clear();
  });
}

const submitPhoto = (data) => {
  uploadPhoto(data.file.file);
}

const uploadPhoto = async (photo_obj) => {
  if(photo_obj.type != 'image/jpeg' && photo_obj.type != 'image/png' && photo_obj.type != 'image/heicd'  && photo_obj.type != 'image/webp'){
    message.warning(t('brand_manager_view_tab_products_photo_format_error'));
    uploadPhotoList.value.clear();
    return false;
  }

  isUploading.value = true;

  let formData = new FormData(); 
  formData.append('file', photo_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      edit_data.value.items_photo.push({
         id: 0,
         sort_id: Math.floor(Math.random() * 10000000000000000000),
         path:res.path,
         temp:res.full_path
      });
      validatePhotos();
      uploadPhotoList.value.clear();
      isUploading.value = false;
    }else {
      uploadPhotoList.value.clear();
      isUploading.value = false;
    }
  }).catch(function (error) {
     uploadPhotoList.value.clear();
     isUploading.value = false;
  });
}

const removePhoto = (item) => {
  var index = edit_data.value.items_photo.indexOf(item);
  if (index !== -1) {
    edit_data.value.items_photo.splice(index, 1);
  }
  validatePhotos();
}

const validateLogo = () => {
  if(!editFuncs.validateLogo(edit_data.value.cover_s,avatar.value,edit_data.value.is_test)){
    validationErrors.value['logo'] = { message: t('brand_manager_view_tab_products_cover_format_error') };
  }else{
    delete validationErrors.value['logo'];
  }
}

const validateName = () => {
  var error = editFuncs.validateName(edit_data.value.name);
  if(error == 1){
    validationErrors.value['name'] = { message: t('brand_manager_view_tab_brand_name_length_error') };
  }else if(error == 2){
    validationErrors.value['name'] = { message: t('brand_manager_view_tab_brand_name_format_error') };
  }else{
    delete validationErrors.value['name'];
  }
}

const validateSeedTypeGender = () => {
  if(!editFuncs.validateSeedTypeGender(edit_data.value.type_gender)){
    validationErrors.value['type_gender'] = { message: 'This field must have at least 1 choices' };
  }else{
    delete validationErrors.value['type_gender'];
  }
}

const validateSeedTypeFlowering = () => {
  if(!editFuncs.validateSeedTypeFlowering(edit_data.value.type_flowering)){
    validationErrors.value['type_flowering'] = { message: 'This field must have at least 1 choices' };
  }else{
    delete validationErrors.value['type_flowering'];
  }
}

const validateSeedTypeSource = () => {
  if(!editFuncs.validateSeedTypeSource(edit_data.value.type_source)){
    validationErrors.value['type_source'] = { message: 'This field must have at least 1 choices' };
  }else{
    delete validationErrors.value['type_source'];
  }
}

const validateSeedGenetics = () => {
  if(!editFuncs.validateSeedGenetics(edit_data.value.gen,edit_data.value.is_test)){
    validationErrors.value['gen'] = { message: t('brand_manager_view_tab_products_genetics_length_error') };
  }else{
    delete validationErrors.value['gen'];
  }
}

const validateSeedHarvest = () => {
  var error = editFuncs.validateSeedHarvest(edit_data.value.harvest_min,edit_data.value.harvest_max,edit_data.value.is_test);
  
  if(error == 1){
    validationErrors.value['harvest_min'] = { message: t('brand_manager_view_tab_products_harvest_format_error') };
  }else if(error == 3){
    validationErrors.value['harvest_min'] = { message: t('brand_manager_view_tab_products_harvest_format_error') };
  }else if(error == 5){
    validationErrors.value['harvest_min'] = { message: t('brand_manager_view_tab_products_harvest_length_error') };
  }else if(error == 7){
    validationErrors.value['harvest_min'] = { message: t('brand_manager_view_tab_products_harvest_min_max_error') };
  }else{
    delete validationErrors.value['harvest_min'];
  }

  if(error == 2){
    validationErrors.value['harvest_max'] = { message: t('brand_manager_view_tab_products_harvest_format_error') };
  }else if(error == 4){
    validationErrors.value['harvest_max'] = { message: t('brand_manager_view_tab_products_field_mandatory_error') };
  }else if(error == 6){
    validationErrors.value['harvest_max'] = { message: t('brand_manager_view_tab_products_harvest_length_error') };
  }else if(error == 8){
    validationErrors.value['harvest_max'] = { message: t('brand_manager_view_tab_products_harvest_format_error') };
  }else{
    delete validationErrors.value['harvest_max'];
  }
}

const validateSeedFlowering = () => {
  var error = editFuncs.validateSeedFlowering(edit_data.value.flowering_min,edit_data.value.flowering_max);
  
  if(error == 1){
    validationErrors.value['flowering_min'] = { message: t('brand_manager_view_tab_products_flowering_min_format_error') };
  }else if(error == 3){
    validationErrors.value['flowering_min'] = { message: t('brand_manager_view_tab_products_flowering_max_length_error') };
  }else if(error == 5){
    validationErrors.value['flowering_min'] = { message: t('brand_manager_view_tab_products_flowering_min_length_error') };
  }else if(error == 7){
    validationErrors.value['flowering_min'] = { message: t('brand_manager_view_tab_products_flowering_min_max_error') };
  }else if(error == 8){
    validationErrors.value['flowering_min'] = { message: t('brand_manager_view_tab_products_field_mandatory_error') };
  }else{
    delete validationErrors.value['flowering_min'];
  }

  if(error == 2){
    validationErrors.value['flowering_max'] = { message: t('brand_manager_view_tab_products_flowering_max_format_error') };
  }else if(error == 4){
    validationErrors.value['flowering_max'] = { message: t('brand_manager_view_tab_products_flowering_max_length_error') };
  }else if(error == 6){
    validationErrors.value['flowering_max'] = { message: t('brand_manager_view_tab_products_flowering_max_length_error') };
  }else{
    delete validationErrors.value['flowering_max'];
  }
}

const validateSeedThc = () => {
  var error = editFuncs.validateSeedThc(edit_data.value.thc_min,edit_data.value.thc_max);
  
  if(error == 1){
    validationErrors.value['thc_min'] = { message: t('brand_manager_view_tab_products_thc_length_error') };
  }else if(error == 3){
    validationErrors.value['thc_min'] = { message: t('brand_manager_view_tab_products_thc_length_error') };
  }else if(error == 5){
    validationErrors.value['thc_min'] = { message: t('brand_manager_view_tab_products_thc_min_max_error') };
  }else{
    delete validationErrors.value['thc_min'];
  }

  if(error == 2){
    validationErrors.value['thc_max'] = { message: t('brand_manager_view_tab_products_thc_length_error') };
  }else if(error == 4){
    validationErrors.value['thc_max'] = { message: t('brand_manager_view_tab_products_thc_length_error') };
  }else{
    delete validationErrors.value['thc_max'];
  }
}

const validateSeedCbd = () => {
  var error = editFuncs.validateSeedCbd(edit_data.value.cbd_min,edit_data.value.cbd_max);
  
  if(error == 1){
    validationErrors.value['cbd_min'] = { message: t('brand_manager_view_tab_products_cbd_format_error') };
  }else if(error == 3){
    validationErrors.value['cbd_min'] = { message: t('brand_manager_view_tab_products_cbd_length_error') };
  }else if(error == 5){
    validationErrors.value['cbd_min'] = { message: t('brand_manager_view_tab_products_thc_min_max_error') };
  }else{
    delete validationErrors.value['cbd_min'];
  }

  if(error == 2){
    validationErrors.value['cbd_max'] = { message: t('brand_manager_view_tab_products_cbd_format_error') };
  }else if(error == 4){
    validationErrors.value['cbd_max'] = { message: t('brand_manager_view_tab_products_cbd_length_error') };
  }else{
    delete validationErrors.value['cbd_max'];
  }
}

const validateSeedHeight = () => {
  var error = editFuncs.validateSeedHeight(edit_data.value.height_min,edit_data.value.height_max);
  
  if(error == 1){
    validationErrors.value['height_min'] = { message: t('brand_manager_view_tab_products_height_format_error') };
  }else if(error == 3){
    validationErrors.value['height_min'] = { message: t('brand_manager_view_tab_products_height_length_error') };
  }else if(error == 5){
    validationErrors.value['height_min'] = { message: t('brand_manager_view_tab_products_height_min_max_error') };
  }else{
    delete validationErrors.value['height_min'];
  }

  if(error == 2){
    validationErrors.value['height_max'] = { message: t('brand_manager_view_tab_products_height_format_error') };
  }else if(error == 4){
    validationErrors.value['height_max'] = { message: t('brand_manager_view_tab_products_height_length_error') };
  }else{
    delete validationErrors.value['height_max'];
  }
}

const validateSeedTastes = () => {
  var error = editFuncs.validateSeedTastes(multipleTasteSelections.value,edit_data.value.is_test);
  if(error == 1){
    validationErrors.value['tastes'] = { message: t('brand_manager_view_tab_products_tastes_size_error') };
  }else if(error == 2){
    validationErrors.value['tastes'] = { message: t('brand_manager_view_tab_products_tastes_size_error') };
  }else{
    delete validationErrors.value['tastes'];
  }
}

const validateSeedEffects = () => {
  var error = editFuncs.validateSeedEffects(multipleEffectSelections.value,edit_data.value.is_test);
  if(error == 1){
    validationErrors.value['effects'] = { message: t('brand_manager_view_tab_products_effects_size_error') };
  }else if(error == 2){
    validationErrors.value['effects'] = { message: t('brand_manager_view_tab_products_effects_size_error') };
  }else{
    delete validationErrors.value['effects'];
  }
}

const validateSeedVegetation = () => {
  var error = editFuncs.validateSeedVegetation(edit_data.value.vegetation_min,edit_data.value.vegetation_max);

  if(error == 1){
    validationErrors.value['vegetation_min'] = { message: t('brand_manager_view_tab_products_flowering_min_format_error') };
  }else if(error == 3){
    validationErrors.value['vegetation_min'] = { message: t('brand_manager_view_tab_products_flowering_min_length_error') };
  }else if(error == 5){
    validationErrors.value['vegetation_min'] = { message: t('brand_manager_view_tab_products_flowering_min_length_error') };
  }else if(error == 7){
    validationErrors.value['vegetation_min'] = { message: t('brand_manager_view_tab_products_flowering_min_max_error') };
  }else{
    delete validationErrors.value['vegetation_min'];
  }

  if(error == 2){
    validationErrors.value['vegetation_max'] = { message: t('brand_manager_view_tab_products_flowering_max_format_error') };
  }else if(error == 4){
    validationErrors.value['vegetation_max'] = { message: t('brand_manager_view_tab_products_flowering_max_length_error') };
  }else if(error == 6){
    validationErrors.value['vegetation_max'] = { message: t('brand_manager_view_tab_products_flowering_max_length_error') };
  }else{
    delete validationErrors.value['vegetation_max'];
  }
}

const validateReleaseYear = () => {
  var error = editFuncs.validateReleaseYear(release_year.value,edit_data.value.is_test);
  if(error == 1){
    validationErrors.value['release_year'] = { message: 'This field must have at least 1 choices' };
  }else if(error == 2){
    validationErrors.value['release_year'] = { message: t('brand_manager_view_tab_products_release_year_error') };
  }else{
    delete validationErrors.value['release_year'];
  }
}

const validateWebLink = () => {
  if(!editFuncs.validateWebLink(edit_data.value.url_website)){
    validationErrors.value['url_website'] = { message: t('brand_manager_view_tab_products_link_format_error') };
  }else{
    delete validationErrors.value['url_website'];
  }
}

const validateDesc = () => {
  wordCounter();
  if(!editFuncs.validateDesc(edit_data.value.description,editDataClone.description)){
    validationErrors.value['description'] = { message: t('brand_manager_view_tab_products_desc_length_error') };
  }else{
    delete validationErrors.value['description'];
  }
}

const validatePhotos = () => {
  if(!editFuncs.validatePhotos(edit_data.value.items_photo)){
    validationErrors.value['photos'] = { message: t('brand_manager_view_tab_products_photos_count_error') };
  }else{
    delete validationErrors.value['photos'];
  }
}

const clearData = () => {
  edit_data.value.vegetation_min = '';
  edit_data.value.vegetation_max = '';
  edit_data.value.ruderalis = 0;
  validateSeedVegetation();
}

const clearDataTest = () => {
  validateLogo();
  validateReleaseYear();
  validateSeedGenetics();
  validateSeedHarvest();
  validateSeedTastes();
  validateSeedEffects();
}

const wordCounter = () => {
  wordsCount.value = editFuncs.wordCounter(edit_data.value.description);
}

wordCounter();


const saveProduct = () => {

  validateLogo();
  validateName();
  validateSeedTypeGender();
  validateSeedTypeFlowering();
  // validateSeedTypeSource();
  validateSeedGenetics();
  validateSeedHarvest();
  validateSeedFlowering();
  validateSeedThc();
  validateSeedCbd();
  validateSeedHeight();
  validateSeedTastes();
  validateSeedEffects();
  validateReleaseYear();
  validateSeedVegetation();
  validateWebLink();
  validateDesc();
  validatePhotos();


  console.log('validationErrors.value');
  console.log(validationErrors.value);

  

  if(Object.keys(validationErrors.value).length>0){
    message.error(t('brand_manager_view_generic_error'));
    return false;
  }


  var params = {};
  params.id = route.params['id'] || 0;
  params.tmp_id = edit_data.value.tmp_id || 0;
  params.avatar = avatar.value;
  params.name = edit_data.value.name;
  //params.type_of = edit_data.value.type_of;
  params.type_gender = edit_data.value.type_gender;
  params.type_flowering = edit_data.value.type_flowering;
  params.type_source = edit_data.value.type_source;
  params.gen = edit_data.value.gen;
  params.harvest_min = edit_data.value.harvest_min;
  params.harvest_max = edit_data.value.harvest_max;
  params.flowering_min = edit_data.value.flowering_min;
  params.flowering_max = edit_data.value.flowering_max;
  params.thc_min = edit_data.value.thc_min;
  params.thc_max = edit_data.value.thc_max;
  params.cbd_min = edit_data.value.cbd_min;
  params.cbd_max = edit_data.value.cbd_max;
  params.height_min = edit_data.value.height_min;
  params.height_max = edit_data.value.height_max;
  //params.release_year = release_year.value.id;
  params.release_year = release_year.value;
  params.set_ind = genes.value ? 1 : 0;
  params.indica_sativa = edit_data.value.indica_sativa;
  params.is_test = edit_data.value.is_test ? 1 : 0;
  //params.is_bestseller = edit_data.value.is_bestseller ? 1 : 0;
  //params.autofl = edit_data.value.autofl ? 1 : 0;
  params.ruderalis = edit_data.value.ruderalis;
  params.vegetation_min = edit_data.value.vegetation_min;
  params.vegetation_max = edit_data.value.vegetation_max;
  params.description = edit_data.value.description;
  params.url_website = edit_data.value.url_website;

  params.items_taste = [];
  for(var k of multipleTasteSelections.value){
    for(var k2 of edit_data.value.items_taste_avaliable){
      if(k2.id == k) 
        params.items_taste.push(k2.name.toLowerCase());
    }
  }

  params.items_effect = [];
  for(var k of multipleEffectSelections.value){
    for(var k2 of edit_data.value.items_effect_avaliable){
      if(k2.id == k) 
        params.items_effect.push(k2.name.toLowerCase());
    }
  }

  // photos 
  var _array = [];
  for(var t2 in editDataClone.items_photo){
    _array.push(editDataClone.items_photo[t2]);
  }

  params.items_photo = [];
  params.items_remove_photo = [];

  if(JSON.stringify(edit_data.value.items_photo) != JSON.stringify(_array)){
    for(var t2 in edit_data.value.items_photo){
      params.items_photo.push(
      {
        id:edit_data.value.items_photo[t2].id,
        sort_id: Math.floor(Math.random() * 10000000000000000000),
        temp:edit_data.value.items_photo[t2].path
      }
      );
    }

    for(var t2 in editDataClone.items_photo){
      var hasPhoto = false;
      for(var t3 in edit_data.value.items_photo){
        if(editDataClone.items_photo[t2].id == edit_data.value.items_photo[t3].id) 
          hasPhoto = true;
      }
      if(!hasPhoto) params.items_remove_photo.push(
      {
        id:editDataClone.items_photo[t2].id,
        temp:editDataClone.items_photo[t2].path
      }
      );
    }
  }
  
  saveProductSend(params);
  //console.log(params);

}

/*const saveProductSend = async (params) => {
  isLoading.value = true;
  await $api.postB2BSaveProduct(params.id, {'seed': params})
  .then(res => {
    if(res && res.status == 'success'){
      message.success(t('brand_manager_view_tab_products_save_success'));
      if(params.id>0 || params.tmp_id>0){
        window.setTimeout('location.reload()', 1000);
      }else{
        window.setTimeout(function(){document.location.href='?action=edit_item&tmp_id=' + res.data.tmp_id ;}, 1000);
      }
      
      isLoading.value = false;
    }
  })
}*/

const saveProductSend = async (params) => {
  isLoading.value = true;
  $api.postB2BSaveProduct(params.id, {'seed': params}).then(res => {
    message.success(t('brand_manager_view_tab_products_save_success'));
    if(res && res.data.value.data.tmp_id){
      edit_data.value.tmp_id = res.data.value.data.tmp_id;
      window.history.pushState({}, null, '0?action=edit_item&tmp_id=' + res.data.value.data.tmp_id);
    }
    isLoading.value = false;
  }).catch(function (error) {
     isLoading.value = false;
     message.error(t('brand_manager_view_generic_error'));
  });
}
 
</script>

<style scoped>



/**/
 
</style>

<style>
 
 /* PRODUCT BRAND */

.prd_edit {
  padding: 20px;
  margin-bottom: 100px;
}

.prd_edit .nr {
  display: flex;
}

.prd_edit .nr .av {
  width: 110px;
  margin-right: 2rem;
  min-height: 110px;
  border: 2px #cecece dashed;
  border-radius: 7px;
  padding: 10px;
}

.prd_edit .nr .av img {
  width: 100%;
}

.prd_edit .nr .nm {
  width: 250px;
}

.prd_edit .nr .fup {
  position: relative;
}

.prd_edit .two_fld {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.prd_edit .two_fld .ind_sat {
  margin-bottom: 1rem!important;
}

.prd_edit .two_fld>.field {
  width: 100%;
}


.right_link {
  float: right;
  color: black;
}

.right_link:hover{
  color: black;
}
.changeset {
  color: #FF0000;
  display: inline-flex;
  align-items: center;
}

.words{
  float: right;
  font-weight: bold;
  margin-top: 6px;
}

.field-words{
  width: 100%;
  text-align: right;
}

.indica{
  float: left;
  width: 250px;
}

.sativa{
  float: right;
  width: 250px;
  text-align: right;
}

/* UPLOADER */


.load_box{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}

.load_box.background{
  min-height: 200px;
}

.no-padding{
  padding: 0px;
}

.load_box::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}

.load_box input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

.load_box_photos{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}

.load_box_photos::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box_photos input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

/* UPLOADER PHOTOS*/

.upload_photos{
  width: 100%;
}

.upload_photos .item{
  float: left;
  width: 100px;
  height: 100px;
  margin-left: 10px;
}

.upload_photos .item_table{
  display: table;
  width: 100px;
  height: 100px;
}

.upload_photos .item_td{
  display: table-cell;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  text-align: center;
}

.upload_photos .item img{
  display: inline-block;
  max-height: 100px;
  max-width: 100px;
}

.upload_photos .delete{
  background-color: rgb(252, 83, 83);
  display: inline-block;
  position: absolute;
  padding: 5px;
  border-radius: 15px;
  margin-top: -5px;
  margin-left: -5px;
  color: white;
  border: 1px white solid;
  cursor: pointer;
}



</style>